.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: var(--padding-left);

  &__image {
    position: absolute;
    left: 0;
    width: 34.5rem;
    z-index: 3;
    transform: translateY(-17rem);
    -webkit-transform: translateY(-17rem);
    -moz-transform: translateY(-17rem);
    -ms-transform: translateY(-17rem);
    -o-transform: translateY(-17rem);
  }

  &__text {
    position: relative;
    z-index: 3;

    &__topText {
      font-size: 3.75rem;
      font-style: normal;
      font-weight: 400;
      margin-top: -2.75rem;
      background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__bottomText {
      text-align: right;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 1.5rem;
    }
  }

  &__starLeft {
    position: absolute;
    left: 22rem;
    margin-top: 2rem;
    width: 17.25rem;
  }

  &__starRight {
    position: absolute;
    right: 18rem;
    margin-top: 6rem;
    width: 10.625rem;
  }
}

@media (max-width: 980px) {
  .testimonials {
    position: relative;
    &__image {
      width: 23.5rem;
      top: 5rem;
    }
  }
}

@media (max-width: 767px) {
  .testimonials {
    position: relative;

    &__image {
      width: 18rem;
      z-index: -1;
      top: 25rem;
    }

    &__text {
      &__topText {
        font-size: 3rem;
        margin-top: 2rem;
      }

      &__bottomText {
        font-size: 1rem;
      }
    }

    &__starRight {
      right: 0;
      margin-top: 8rem;
      width: 10.625rem;
    }
  }
}
