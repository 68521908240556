.numbers {
  display: grid;
  border: 2px solid #635a77;
  background: rgba(41, 31, 60, 0.37);
  backdrop-filter: blur(0.625rem);
  margin: 0 var(--padding-left);
  margin-top: 3rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  padding: 2rem;
  position: relative;

  &__topText {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem; /* 125% */
    background: linear-gradient(180deg, #fff 43.68%, #c9acff 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__midText {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(91deg, #d985ff 0%, #9f6dff 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    span {
      background: linear-gradient(91deg, #d985ff 0%, #9f6dff 70%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
    }
  }

  &__bottomText {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(180deg, #fff 43.68%, #c9acff 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -2px;
    right: -2px;
    height: 1rem;
    background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
    backdrop-filter: blur(10px);
  }
}

.leftBlur {
  position: absolute;
  top: -40%;
  width: 45rem;
  z-index: -1;
  opacity: 0.65;
  left: -10%;
}

.rightBlur {
  position: absolute;
  top: -40%;
  width: 45rem;
  z-index: -1;
  opacity: 1;
  right: -10%;
}

@media (max-width: 767px) {
  .numbers {
    grid-template-columns: 1fr;
    grid-gap: 5rem;
  }
}
