.premeLocks {
  padding-top: 3.5rem;
  display: flex;
  padding-left: var(--padding-left);

  &__text {
    font-style: normal;
    font-weight: 400;

    &__topText {
      background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      font-size: 3.75rem;
      -webkit-text-fill-color: transparent;
    }

    &__bottomText {
      transform: translateY(-3.25rem) translateX(1rem);
      background: linear-gradient(to right, #d985ff 0%, #9f6dff 30%);
      background-clip: text;
      font-size: 3.75rem;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-transform: translateY(-3.25rem) translateX(1rem);
      -moz-transform: translateY(-3.25rem) translateX(1rem);
      -ms-transform: translateY(-3.25rem) translateX(1rem);
      -o-transform: translateY(-3.25rem) translateX(1rem);
    }
  }

  &__subText {
    max-width: 35ch;
    font-size: 1.25rem;
    margin-top: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6875rem;
    background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &__sequel65 {
      display: inline-block;
      background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Sequel100Black-65;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6875rem;
    }
  }

  &__svg {
    margin-left: 4.6875rem;
  }

  &__currency_image {
    width: 21.125rem;
    position: absolute;
    right: -4.75rem;
    top: -0rem;
  }

  &__dots {
    margin-left: 3rem;
  }
}

@media (max-width: 767px) {
  .premeLocks {
    flex-direction: column;
  }

  .premeLocks__text__topText,
  .premeLocks__text__bottomText {
    font-size: 3rem;
  }

  .premeLocks__text__bottomText {
    transform: translateY(-2.25rem) translateX(1rem);
    -webkit-transform: translateY(-2.25rem) translateX(1rem);
    -moz-transform: translateY(-2.25rem) translateX(1rem);
    -ms-transform: translateY(-2.25rem) translateX(1rem);
    -o-transform: translateY(-2.25rem) translateX(1rem);
  }

  .premeLocks {
    &__currency_image {
      width: 13rem;
      right: -2rem;
      top: -2rem;
    }

    &__svg {
      margin: 0 auto;
      width: 60%;
    }

    &__dots {
      margin-left: 3rem;
      position: absolute;
      bottom: 0;
      right: -3rem;
    }
  }
}
