.header {
  display: flex;
  align-items: center;
  padding-top: 4.8125rem;
  padding-left: var(--padding-left);
}

.dashBtn {
  &:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
  }

  width: max-content;
  padding: 0.875rem 2.25rem;
  border-radius: 0.125rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.headerLink,
.headerLinkActive {
  color: #fff;
  font-family: Sequel45;
  text-decoration: none;
  margin-left: 1.875rem;
}

.headerLink:hover {
  text-decoration: underline;
}

.headerLinkActive {
  font-family: Sequel75;
}
