.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background: rgba(6, 3, 12, 0.5);
  backdrop-filter: blur(10px);
  transition: left 0.3s ease-in-out;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mobile-sidebar.open {
  left: 0;
}

.toggle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px;
  position: absolute;
  z-index: 15;

  svg {
    width: 27px;
  }
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

.links {
  margin-top: -20%;
  padding-top: 80px;
}

.links ul {
  list-style: none;
  padding: 0;
}

.links li {
  margin: 15px 0;
}

.links a {
  text-decoration: underline;
  color: #fff;

  div {
    text-transform: uppercase;
    color: #fff;
    font-size: 22px;
    transition: color 0.3s ease-in-out;
  }
}
.dash-btn {
  border-radius: 2px;
  background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
  color: #fff;
  width: max-content;
  margin: 0 auto;
  padding: 1rem 2rem;

  div {
    font-size: 2rem;
  }
}

.links a:hover {
  color: #66ccff;
}
