.heroRight {
  display: inline-block;
  pointer-events: none;

  &__currency_image_container {
    img {
      width: 25rem;
    }
    width: 21.7757rem;
    position: absolute;
    will-change: transform;
    transform: rotate(8.563deg) translate3d(0, 0, 0);
    top: -7rem;
    left: 50%;
    -webkit-transform: rotate(8.563deg) translate3d(0, 0, 0);
    -moz-transform: rotate(8.563deg) translate3d(0, 0, 0);
    -ms-transform: rotate(8.563deg) translate3d(0, 0, 0);
    -o-transform: rotate(8.563deg) translate3d(0, 0, 0);
  }

  &__currency_blur {
    position: absolute;
    top: 17%;
    left: -47%;
    border-radius: 0.25rem;
    transform: translate3d(0, 0, 0);
    width: 36.4375rem !important;
    will-change: transform;
    height: 30.5625rem;
    object-fit: contain;
    flex-shrink: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
  }

  &__currency_blur2 {
    width: 25.4375rem;
    height: 6.5625rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    will-change: transform;
    position: absolute;
    top: 6.6875rem;
    right: -8.125rem;
  }

  &__currency_image_container2 {
    position: absolute;
    top: 0;
    right: 0;

    &__currency {
      position: absolute;
      top: -3rem;
      right: 7.9375rem;
      width: 10.7255rem;
      height: 12.9652rem;
      transform: rotate(171.437deg);
      -webkit-transform: rotate(171.437deg);
      -moz-transform: rotate(171.437deg);
      -ms-transform: rotate(171.437deg);
      -o-transform: rotate(171.437deg);
    }

    &__currency2 {
      position: absolute;
      width: 14.5321rem;
      height: 17.5667rem;
      top: 9.8125rem;
      right: -5rem;
      transform: rotate(8.5deg) translate3d(0, 0, 0);
      will-change: transform;
      -webkit-transform: rotate(8.5deg) translate3d(0, 0, 0);
      -moz-transform: rotate(8.5deg) translate3d(0, 0, 0);
      -ms-transform: rotate(8.5deg) translate3d(0, 0, 0);
      -o-transform: rotate(8.5deg) translate3d(0, 0, 0);
    }

    &__mobile {
      position: absolute;
      top: 10.4375rem;
      right: 0;
      height: 36.9375rem;
      z-index: -1;
    }

    &__heroImg {
      position: absolute;
      top: 7.5rem;
      right: 1.8125rem;
      width: 34.6875rem;
      height: 34.125rem;
    }

    &__circle {
      position: absolute;
      top: -2.5rem;
      right: 2.25rem;
      width: 11.25rem;
    }

    &__dots {
      position: absolute;
      top: 1.375rem;
      right: -1.875rem;
      width: 10.7255rem;
      height: 12.9652rem;
    }

    &__smblur {
      position: absolute;
      top: -6.5rem;
      right: -15rem;
      width: 46.1875rem;
      height: 46rem;
      flex-shrink: 0;
    }
  }
}

@media (max-width: 850px) {
  .heroRight {
    img {
      transform: scale(0.7) !important;
      -webkit-transform: scale(0.7) !important;
      -moz-transform: scale(0.7) !important;
      -ms-transform: scale(0.7) !important;
      -o-transform: scale(0.7) !important;
    }
  }
}
