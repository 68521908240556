.heroLeft {
  padding-left: var(--padding-left);
  margin-top: 6.25rem;
}

.stripedCircle {
  position: absolute;
  left: -5.65rem;
  bottom: 3rem;
  width: 11.3125rem;
  height: 11.3125rem;
  pointer-events: none;
  z-index: -4;
}

.dotsGrid {
  position: absolute;
  left: -4.7rem;
  bottom: 12.5rem;
  width: 7.5842rem;
  height: 14.7826rem;
  pointer-events: none;
  z-index: -1;
}

.primaryText {
  font-size: 3.125rem;
  font-weight: 400;
  display: inline-block;
  background: linear-gradient(91deg, #d985ff 0%, #6e43bd 70%, #d985ff 100%);
  background-size: 200% 200%;
  animation: gradientAnimation 4s linear infinite alternate;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-animation: gradientAnimation 4s linear infinite alternate;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.secondaryText {
  font-size: 6.25rem;
  font-style: normal;
  font-weight: 400;
  max-width: 10ch;
  background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  -webkit-text-fill-color: transparent;
}

.secondaryText2 {
  margin-top: -3rem;
}

.btns {
  display: flex;
  margin-top: 3.5rem;
}

.button {
  transition: all 0.3s ease;
  outline: none;
  border: none;
  height: max-content;
  padding: 1rem 0;
  color: #fff;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0625rem;
  cursor: pointer;
  margin-right: 1.5rem;
  border-radius: 0.125rem;
  background: linear-gradient(46deg, #59c49a 0%, #97f5d0 100%);
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -ms-border-radius: 0.125rem;
  -o-border-radius: 0.125rem;
  max-width: 11rem;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
  }
}

.button[data-type="more-info"] {
  background: url("../../assets/images/btnBg.svg");
  backdrop-filter: blur(0.3125rem);
  will-change: transform;
  color: #fff;
  background-size: 100% 100%;
  position: relative;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
  }

  @media (max-width: 767px) {
    background-size: 106% 100%;
  }
}

.button:first-child {
  position: relative;
  overflow: hidden;
}

.button:first-child:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -150%;
  transform: skewX(-45deg);
  background: linear-gradient(60deg, rgba(255, 255, 255, 0.5), transparent);
  pointer-events: none;
  opacity: 0;
  animation: shineAnimation 2s ease-in-out infinite;
  z-index: 1;
  -webkit-animation: shineAnimation 2s ease-in-out infinite;
}

@keyframes shineAnimation {
  0% {
    left: -150%;
    opacity: 0;
  }
  100% {
    left: 150%;
    opacity: 1;
  }
}

.mobRight {
  position: absolute;
  right: 0;
  top: 0;
  width: 24rem;
  z-index: -1;
}

@media (max-width: 1000px) {
  .primaryText {
    font-size: 2.5rem;
  }

  .secondaryText {
    font-size: 5rem;
  }

  .heroStars {
    display: none;
  }
}

@media (max-width: 767px) {
  .heroLeft {
    padding-right: var(--padding-left);
  }

  .primaryText {
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }

  .secondaryText {
    font-size: 4rem;
    line-height: 7rem;
    text-align: center;
    max-width: none;
  }

  .btns {
    justify-content: center;
    margin-top: 0.5rem;
  }

  .btns div {
    font-size: 12px;
    line-height: 12px;
    color: #fff;
  }

  .button {
    background: linear-gradient(46deg, #59c49a 0%, #97f5d0 100%);
  }

  .button:last-child {
    margin-right: 0;
  }
}
