.stars {
  width: 14.125rem;
  height: 10.625rem;
  position: relative;
  margin-left: 5rem;
}

.star1 {
  position: absolute;
  top: 0;
  left: 0;
}

.star2 {
  position: absolute;
  top: 1.5rem;
  right: 0;
}

.star3 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
