@font-face {
  font-family: HurmeGeometricSans3;
  src: url("./assets/fonts/HurmeGeometricSans3-Bold.ttf");
}

@font-face {
  font-family: sequel45;
  src: url("./assets/fonts/Sequel100Black-45.ttf");
}

@font-face {
  font-family: sequel65;
  src: url("./assets/fonts/Sequel100Black-65.ttf");
}

@font-face {
  font-family: sequel75;
  src: url("./assets/fonts/Sequel100Black-75.ttf");
}

@font-face {
  font-family: sequel76;
  src: url("./assets/fonts/Sequel100Black-76.ttf");
}

@font-face {
  font-family: sequel85;
  src: url("./assets/fonts/Sequel100Black-85.ttf");
}

@font-face {
  font-family: sequel86;
  src: url("./assets/fonts/Sequel100Black-86.ttf");
}

@font-face {
  font-family: TTCommonsRegular;
  src: url("./assets/fonts/TTCommonsRegular.otf");
}

:root {
  --padding-left: 100px;
}

html,
body {
  position: relative;
  overflow-x: clip;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --size: calc(0.5vmax + 0.5vmin);
  font-size: min(calc(1.35 * var(--size)), 1rem);
}

.bg {
  position: absolute;
  top: -215px;
  left: -1170px;
  pointer-events: none;
  width: 2190px;
  z-index: -1;
}

.clothingBackdoors__bg {
  position: absolute;
  top: 23px;
  right: -722px;
  pointer-events: none;
  width: 136.875rem;
  z-index: -4;
  transform: scaleX(-1) rotate(20deg);
  -webkit-transform: scaleX(-1) rotate(20deg);
  -moz-transform: scaleX(-1) rotate(20deg);
  -ms-transform: scaleX(-1) rotate(20deg);
  -o-transform: scaleX(-1) rotate(20deg);
}

body {
  background-color: #06030c;
}

.container {
  max-width: 90rem;
  margin: 0 auto;
}

.hero {
  display: flex;
}

.hero_logo {
  width: 35px;
  margin-right: 14px;
}

.hero_logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

li {
  list-style: none;
}

.slick-list {
  margin: 0 -0.9375rem !important;
  padding: 0.625rem 0 !important;
}
.slick-slide > div {
  padding: 0 1.5625rem;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

.mob-stars-1 {
  position: absolute;
  top: 280px;
  right: 80px;
  max-width: 204px;
  width: 18vw;
  z-index: 1;
}

.mob-stars-2 {
  position: absolute;
  bottom: -10px;
  height: max-content;
  left: 36px;
  width: 24vw;
  max-width: 277px;
  z-index: 1;
}

@media (max-width: 1400px) {
  :root {
    --padding-left: 20px;
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  :root {
    --padding-left: 20px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  :root {
    --padding-left: 16px;
    font-size: 12px;
  }

  .hero {
    flex-direction: column;
    padding-top: 6rem;
  }

  .mob-hero {
    padding-left: 3rem;
  }

  .numbers__bg {
    top: 25rem;
  }
}
