.faq {
  &__topText {
    font-size: 4rem;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    text-align: center;
    -webkit-text-fill-color: transparent;
  }

  &__bottomText {
    text-align: center;
    text-shadow: 0rem 0rem 0rem 0rem rgba(9, 13, 43, 0.3),
      -0.8125rem 0.8125rem 2.625rem 0rem rgba(9, 13, 43, 0.29),
      -3.375rem 3.375rem 4.75rem 0rem rgba(9, 13, 43, 0.26),
      -7.5625rem 7.5625rem 6.4375rem 0rem rgba(9, 13, 43, 0.15),
      -13.5rem 13.5rem 7.625rem 0rem rgba(9, 13, 43, 0.04),
      -21.0625rem 21.0625rem 8.375rem 0rem rgba(9, 13, 43, 0.01);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 125% */
    background: linear-gradient(178deg, #fff 0%, #c9acff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    max-width: 50ch;
    margin: 0 auto;
    -webkit-text-fill-color: transparent;
  }

  &__leftImage {
    position: absolute;
    width: 50rem;
    left: 0;
    margin-top: -32.5rem;
  }

  &__rightImage {
    position: absolute;
    width: 30rem;
    right: 0;
    margin-top: -5.5rem;
  }
}

.accordion {
  max-width: 52rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;

  &__item {
    margin-bottom: 2rem;
    padding: 1.75rem 2rem;
    border-radius: 0.625rem;
    background: linear-gradient(
      315deg,
      rgba(216, 133, 255, 0.1) 0%,
      rgba(3, 4, 11, 0) 43.22%,
      rgba(161, 110, 255, 0.1) 100%
    );
    backdrop-filter: blur(25px);
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    -ms-border-radius: 0.625rem;
    -o-border-radius: 0.625rem;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

    &__question {
      cursor: pointer;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

    &__answer {
      color: #fff;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

.accordion__item:hover {
  background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);

  .accordion__item__question {
    color: #02030a;

    svg path {
      fill: #000;
    }
  }
}

@media (max-width: 767px) {
  .faq {
    padding: 0 16px;

    &__topText {
      font-size: 3.5rem;
      margin-bottom: 2rem;
    }

    &__rightImage {
      width: 20rem;
      margin-top: -5.5rem;
      z-index: -1;
    }
  }

  .accordion__item__answer {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
}
