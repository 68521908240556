.horizontalBar {
  height: 5.8125rem;
  background: linear-gradient(
    91deg,
    rgba(217, 133, 255, 0.4) 0%,
    rgba(159, 109, 255, 0.4) 100%
  );
  backdrop-filter: blur(5px);
  z-index: -2;
  margin-top: 2.25rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__logoWrapper {
    position: absolute;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    margin-top: -16px;
  }

  &__topBar {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
    height: 4px;
    width: 100%;
  }

  &__bottomBar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 21px;
    width: 100%;
    background: var(--linear, linear-gradient(91deg, #d985ff 0%, #9f6dff 100%));
  }
}
