.slick {
  border-radius: 1.5625rem 0rem;
  background: #36393f;
  padding: 1.25rem 1.625rem;
  position: relative;
  margin: 0 1.875rem;
  min-height: 10.75rem;
  margin-top: 1rem;

  .bg {
    inset: -1px;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 1.5625rem 0rem;
    background: linear-gradient(to bottom, #fff 0%, transparent 100%);
  }

  &__text {
    color: #fff;
    font-size: 0.625rem;
    margin-top: 1.375rem;
    margin-bottom: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.75rem; /* 120% */
  }

  &__date {
    color: #fff;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.75rem; /* 120% */
  }
}

.slickUser__text {
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem; /* 85.714% */
}

.active {
  min-height: 13rem;
  margin-top: 0;
  .bg {
    inset: -1px;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 1.5625rem 0rem;
    background: linear-gradient(to right, #d985ff 0%, #9f6dff 100%);
  }

  .slickUser__text {
    background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.slickUser {
  display: flex;
  align-items: center;

  &__image {
    height: 2.625rem;
    object-fit: contain;
    margin-right: 1.125rem;
  }
}

.slickContainer {
  padding: 5rem 0;
  padding-top: 9.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick:first-child,
.slick:last-child {
  flex-basis: 373px;
  flex-grow: 1;
  flex-shrink: 0.5;
}

.slick:nth-child(2) {
  flex-grow: 1;
  flex-basis: 500px;
  flex-shrink: 0.5;
}

@media (max-width: 1080px) {
  .slickContainer {
    flex-direction: column;
    gap: 1.5rem;
    margin: 0 1rem;
    z-index: 5;
    position: relative;
  }

  .slick:first-child,
  .slick:last-child {
    flex-basis: 100%;
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .slick {
    flex-basis: 100% !important;

    margin: 0;
    &__text,
    &__date {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  .slickUser__text {
    color: #fff;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 85.714% */
  }
}
