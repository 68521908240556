.aboutUs {
  padding-top: 2.4375rem;
  padding-left: var(--padding-left);

  &__text {
    font-style: normal;
    font-weight: 400;
    margin-bottom: -1rem;

    &__topText {
      font-size: 3.75rem;
      background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__subText {
    max-width: 46ch;
    margin-top: 1.625rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 3;

    &__sequel65 {
      display: inline;
      background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }

  &__whop__container {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  &__whop {
    margin-right: 2rem;
    cursor: pointer;

    &__star {
      margin-right: 0.25rem;
    }

    &__text {
      margin-bottom: 0.5rem;
      background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 100% */
    }
  }
}

@media (max-width: 767px) {
  .aboutUs__text__topText {
    font-size: 3rem;
  }
}
