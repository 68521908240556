.clothingBackdoors {
  margin-top: 6.0625rem;
  padding-left: var(--padding-left);

  &__leftImg {
    position: absolute;
    left: 0;
    width: 35.25rem;
  }

  &__rightImg {
    position: absolute;
    right: 0;
    top: -6rem;
    width: 60rem;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1rem;

    &__topText {
      background: linear-gradient(to bottom, #fff 0%, #c9acff 300%);
      background-clip: text;
      -webkit-background-clip: text;
      font-size: 3.75rem;
      -webkit-text-fill-color: transparent;
    }

    &__bottomText {
      transform: translateY(-3.15rem) translateX(1rem);
      background: linear-gradient(to right, #d985ff 0%, #9f6dff 30%);
      background-clip: text;
      font-size: 3.75rem;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-transform: translateY(-3.15rem) translateX(1rem);
      -moz-transform: translateY(-3.15rem) translateX(1rem);
      -ms-transform: translateY(-3.15rem) translateX(1rem);
      -o-transform: translateY(-3.15rem) translateX(1rem);
    }
  }

  &__subText {
    max-width: 35ch;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6875rem;
    background: linear-gradient(179deg, #fff 0%, #c9acff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &__sequel65 {
      display: inline-block;
      background: linear-gradient(91deg, #d985ff 0%, #9f6dff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Sequel100Black-65;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6875rem;
    }
  }

  &__stars {
    margin: 0;
    margin-left: 7.5rem;
    margin-top: 3rem;
  }

  &__rightBlur {
    position: absolute;
    right: 0;
    top: -20rem;
    width: 30.375rem;
    z-index: -3;
  }

  &___bg {
    position: absolute;
    top: -13.4375rem;
    left: -73.125rem;
    pointer-events: none;
    width: 136.875rem;
    z-index: -1;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
  }

  &__currency_image {
    position: absolute;
    top: -2rem;
    right: -5rem;
    width: 20.4375rem;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 1024px) {
  .clothingBackdoors {
    &__rightImg {
      position: relative;
      top: -5rem;
      width: 100%;
      margin-bottom: -25rem;
    }
  }
}

@media (max-width: 767px) {
  .clothingBackdoors {
    &__leftImg {
      position: absolute;
      left: 0;
      width: 20.25rem;
      top: 22%;
    }

    &__rightImg {
      width: 100%;
      top: 2rem;
      position: relative;
      margin-bottom: 0;
    }

    &__stars {
      margin-top: 0;
    }

    &__text {
      &__topText {
        font-size: 3rem;
      }

      &__bottomText {
        font-size: 3rem;
        transform: translateY(-2.25rem) translateX(1rem);
        -webkit-transform: translateY(-2.25rem) translateX(1rem);
        -moz-transform: translateY(-2.25rem) translateX(1rem);
        -ms-transform: translateY(-2.25rem) translateX(1rem);
        -o-transform: translateY(-2.25rem) translateX(1rem);
      }
    }
  }
}
